import React from "react";
import DateTime from './DateTime';
import Text from './Text';
import Image from './Image';
import Speed from './Speed';
import Vehicle from './Vehicle';
import Alert from './Alert';
import RouteList from './RouteList';
import RouteTable from './RouteTable';
import TransferTable from './TransferTable';
import config from '../config/config';

const Component = (props) => {
    const position = props.position || {};
    const background = props.background || {};
    const font = props.font || {};
    const styles = {
        display: 'flex',
        left: (position.x || 0),
        top: position.y || 0,
        width: position.width || 0,
        height: position.height || 0,
        zIndex: position.z || 1,
        backgroundColor: background.color || '',
        padding: background.padding ? background.padding : '0px',
        borderRadius: background.round ? background.round + 'px' : '0px',
        alignItems: background.valign || 'flex-start',
        justifyContent: background.flexAlign || 'flex-start',
        textAlign: background.align || 'left',
        color: font.color || '',
        fontFamily: font.type || '',
        fontSize: font.size ? font.size + 'px' : '20px',
        fontWeight: font.bold ? 'bold' : 'normal',
        fontStyle: font.italic ? 'italic' : 'normal',
        textDecoration: font.underlined ? 'underline' : 'none',
        backgroundImage: background.image ? `url(${config.api.media}${background.image})` : '',
    };
    return (
        <div className="component" style={styles} id={props.id}>
            {props.children}
        </div>
    );
}

export default Component;

export const componentTypes = {
    'TemplateItemDateTime': DateTime,
    'TemplateItemTextStatic': Text,
    'TemplateItemImage': Image,
    'TemplateItemSpeed': Speed,
    'TemplateItemVehicle': Vehicle,
    'TemplateItemAlert': Alert,
    'TemplateItemRouteList': RouteList,
    'TemplateItemRouteTable': RouteTable,
    'TemplateItemTransferTable': TransferTable,
};