import config from '../../config/config';

/*export const loadTemplate = (id) => (dispatch, getState) => Promise.resolve().then(() => {
    fetch(`${config.api.template}${id}.json`, { crossDomain: true })
    .then(data => data.json())
    .then(data => {
        return dispatch({ type: 'TEMPLATE_LOADED', data });
    })
    .catch(err => {
        console.log(err);
    });

});*/


export const loadTemplate = (id) => {
    return (dispatch) => {
        return fetch(`${config.api.template}${id}.json`, { crossDomain: true })
            .then(response => response.json())
            .then(json => dispatch({ type: 'TEMPLATE_LOADED', data: json }));
    }
}

/*export const loadTemplate = (id) => {
    return fetch(`${config.api.template}${id}.json`, { crossDomain: true })
        .then(data => data.json())
        .then(data => {
            return dispatch({ type: 'TEMPLATE_LOADED', data });
        })
        .catch(err => {
            console.log(err);
        });
}*/


export const loadScreen = (id) => {
    return (dispatch, getState) => {
        fetch(`${config.api.screen}${id}`, { crossDomain: true })
            .then(data => data.json())
            .then(data => {
                dispatch({ type: 'SCREEN_LOADED', data, id });
            })
            .catch(err => {
                console.log(err);
            });
    }
}

export const loadData = (url = null) => {
    // load demo data
    if(!url){
        url = config.api.demoData;
    }
    return (dispatch) => {
        return fetch(url, { crossDomain: true })
            .then(response => response.json())
            .then(json => dispatch({ type: 'DATA_LOADED', data: json }));
    }
}

export const updateTime = () =>
{
    return { type: 'UPDATE_TIME', time: new Date() };

}