import React, { Component } from 'react';


class TransferTable extends Component {

    state = {
        tableHeight: 300,
        rowHeight: 0,
        needToScroll: false,
        textTimerDate: null,
    }

    /*calculateTableHeight() {
        //console.log('calculateTableHeight');
        const container = document.getElementById(this.props.id);
        const rows = container.querySelectorAll("tr");
        if (rows && rows.length > 0) {
            //const containerHeight = container.clientHeight;
            const rowHeight = rows[0].clientHeight;
            const needToScroll = this.props.data.route.length > this.props.route.lines;
            this.setState({
                tableHeight: rowHeight * this.props.route.lines,
                rowHeight,
                needToScroll,
            });
        }
    }*/

    /*componentDidMount() {
        this.calculateTableHeight();
        this.scrollTable(true);
        this.setTimer();
    }*/

    /*setTimer() {
        const now = new Date().getTime();
        const addTime = this.props.route.scrollTime * 1000;
        this.setState({
            textTimerDate: new Date(now + addTime),
        });
    }*/

    /*lastItemIsVisible() {
        const container = document.querySelector("#" + this.props.id + " div");
        const rows = container.querySelectorAll("tr");
        if (rows && rows.length > 0) {
            const lastRow = rows[rows.length-1];
            return lastRow.offsetTop + lastRow.clientHeight - container.scrollTop <= container.clientHeight + 5;
        }
        return false;
    }*/
    
    /*scrollTable(toTop = false) {
        const container = document.querySelector("#" + this.props.id + " div");
        if (toTop || this.lastItemIsVisible()) {
            container.scroll({
                top: 0,
                behavior: 'smooth',
            });

        } else {
            container.scrollBy({
                top: this.state.rowHeight,
                behavior: 'smooth',
            });
        }
    }*/


    /*componentDidUpdate(prevProps) {
        // route data is updated
        if (this.props.data.route !== prevProps.data.route) {
            this.calculateTableHeight();
            this.scrollTable(true);
            this.setTimer();
        }
        if (this.state.needToScroll && this.state.textTimerDate && this.state.textTimerDate <= new Date()) {
            this.scrollTable();
            this.setTimer();
        }

    }*/

    render() {
        const transfer = this.props.transfer || {};
        const data = this.props.data.transfer || [];
        //console.log(route, data);
        return (
            <div style={{ overflow: 'hidden', width: '100%', height: this.state.tableHeight + 'px' }}>
                <table className="transferTable">
                    <tbody>
                        {data.map((row, index) => {
                            return this.renderRow(row, transfer, index);
                        })}
                    </tbody>
                </table>
            </div>
        );
    }

    renderRow(data, transfer, index) {
        return (
            <tr key={index}>
                <td className="transferTableItem">
                    <Time time={data.time.expected} prefix={transfer.timePrefix} size={this.props.font.size} />
                </td>
                <td className="transferTableItem">
                    <Time time={data.time.planned} prefix={transfer.timePrefix} color={transfer.expectedColor} size={this.props.font.size} />
                </td>
                <td className="transferTableItem">
                    {data.number}
                </td>
                <td className="transferTableItemDestination">
                    {data.destination}
                </td>
                <td className="transferTableStationPart">
                    {data.stationPart}
                </td>
            </tr>
        );
    }


}

const Time = (props) => {
    const { time, prefix, size } = props;
    const color = props.color || '';
    return (
        <span style={{ color: color }}>
            <span style={{ fontSize: size * 0.68 + 'px' }}>{prefix}</span> {formatTime(new Date(time * 1000))}
        </span>
    );
}

const formatTime = (time) => {
    const h = time.getHours();
    const hh = h < 10 ? '0' + h : h;
    const m = time.getMinutes();
    const mm = m < 10 ? '0' + m : m;
    return hh + ':' + mm;
}

export default TransferTable;