const initState = {
};

const templateReducer = (state = initState, action) => {
    switch (action.type) {
        case 'TEMPLATE_LOADED':
            console.log('TEMPLATE_LOADED'/*, action.data*/);
            return {
                ...state, 
                ...action.data,
            };
        default:
            return state;
    }
};

export default templateReducer;