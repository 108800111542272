import React from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from '../redux/store';
import * as actionCreators from '../redux/actions/actionCreators';
import Component, { componentTypes } from './Component';
import DataConverter from '../data/DataConverter';
import config from '../config/config';

class Screen extends React.Component {
    
    constructor(props) {
        super(props);
        if(props.match && props.match.params && props.match.params.id) {
            this.id = props.match.params.id;
        }
        else {
            this.id = null;
        }
    }

    saveId() {
        if(this.props.id) {
            this.id = this.props.id;
        }
        else if(this.props.match && this.props.match.params && this.props.match.params.id) {
            this.id = this.props.match.params.id;
        }
    }

    componentDidMount() {
        this.saveId();
        
        // start the updat etimer
        this.updateTimer = setInterval(() => {
            this.props.updateTime();
        }, 1000);

        if (!this.props.screens[this.id]) {
            this.props.loadScreen(this.id);
        }
        // this is the case when the screen is opened directly (not from a template)
        // so the data json should be dowloaded
        if(!this.props.id){
            //setTimeout(()=>{this.props.loadData();}, 5000);
            this.props.loadData()
        }
    }

    componentDidUpdate(prevProps) {
        this.saveId();
        if (!this.props.screens[this.id]) {
            this.props.loadScreen(this.id);
        }
    }

    createComponent(component, data, time) {
        //console.log(component);
        const TagName = componentTypes[component.className];
        return TagName ? <TagName {...component} data={data} time={time}/> : null;
    }

    render() {
        const id = this.id;
        const screen = this.props.screens[id];
        if (!screen) {
            return (
                <div />
            );
        }
        if (screen.code && screen.code !== 0) {
            return (
                <div>{screen.message}</div>
            );
        }
        const styles = {
            backgroundColor: screen.content.properties.backgroundColor || '',
            backgroundImage: `url("${config.api.media}${screen.content.properties.backgroundId}.${screen.content.properties.backgroundExt}")`,
        };
        return (
            <div className="screen" style={styles}>
                {screen.content.items.map((component, index) => {
                    const convertedComponent = DataConverter.convertScreenData(component);
                    return (
                        <Component key={index} {...convertedComponent}>
                            {this.createComponent(convertedComponent, this.props.data, this.props.time)}
                        </Component>
                    );
                })}
            </div>
        );
    }
}

export default connect(mapStateToProps, actionCreators)(Screen);