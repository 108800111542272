import React from 'react';
import config from '../config/config';

const Image = (props) => {
    //console.log(props);
    const { image } = props;
    return(
        <img src={`${config.api.media}${image.mediaId}.${image.ext}`} alt={image.name}></img>
    );
}

export default Image;