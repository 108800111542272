import React, { Component } from 'react';
import DataParser from '../data/DataParser';

class Speed extends Component {
    
    render() {
        //console.log(this.props.data);
        const data = this.props.data || {};
        const speed = DataParser.parse(data, '[speed]') || 0;
        //console.log(speed);
        const text = speed > this.props.speed.minValue ? `${speed} ${this.props.speed.postfix}` : null;
        return (
            <p>{text}</p>

        );
    }
}

export default Speed;