import React, { Component } from 'react';
import { connect } from 'react-redux';
import { mapStateToProps } from '../redux/store';
import * as actionCreators from '../redux/actions/actionCreators';
import Screen from './Screen';

class Template extends Component {

    state = {
        screenIndex: -1,
    };

    componentDidMount() {
        const id = this.props.match.params.id;
        this.props.loadTemplate(id)
            .then(() => {
                //console.log(this.props);
                this.goToNextScreen();
            })
            .then(() => {
                //setTimeout(() => { this.props.loadData(); }, 10000);
                this.props.loadData()
            })
            .catch(err => {
                console.log(err);
            });
    }

    goToNextScreen = () => {
        const { template } = this.props;
        if (template.screens) {
            const nextScreenIndex = this.state.screenIndex < template.screens.length - 1 ? this.state.screenIndex + 1 : 0;
            
            // calculate time for next screen
            const screenTime = template.screens[nextScreenIndex].time * 1000;
            // reset screen timer
            if (this.screenTimer) {
                clearInterval(this.screenTimer);
            }
            // start screen timer
            this.screenTimer = setInterval(() => {
                this.goToNextScreen();
            }, screenTime);
            
            this.setState({
                screenIndex: nextScreenIndex
            });
        }
    }

    render() {
        //console.log(this.props.data);
        const { template } = this.props;
        if (!template.id) {
            return (
                <p>Loading...</p>
            );
        }
        if (!template.screens) {
            return (
                <p>No screen found in this template</p>
            );
        }
        const screen = template.screens[this.state.screenIndex];
        if (!screen) {
            return null;
        }
        return (
            <div>
                <Screen id={screen.id} />
            </div>
        );
    }
}

export default connect(mapStateToProps, actionCreators)(Template);