import React, { Component } from 'react';
import config from '../config/config';

class RouteTable extends Component {

    state = {
        contentHeight: 0,
        itemHeight: 0,
        needToScroll: false,
        textTimerDate: null,
    }

    calculateTableHeight() {
        //console.log('calculateTableHeight');
        const container = document.getElementById(this.props.id);
        const items = container.querySelectorAll(".gridContainer div");
        if (items && items.length > 0) {
            //const containerHeight = container.clientHeight;
            const itemHeight = items[0].clientHeight;
            const needToScroll = this.props.data.route.length > this.props.route.lines;
            this.setState({
                contentHeight: itemHeight * this.props.route.lines,
                itemHeight,
                needToScroll,
            });
        }
    }

    componentDidMount() {
        this.calculateTableHeight();
        this.scrollTable(true);
        this.setTimer();
    }

    setTimer() {
        const now = new Date().getTime();
        const addTime = this.props.route.scrollTime * 1000;
        this.setState({
            textTimerDate: new Date(now + addTime),
        });
    }

    lastItemIsVisible() {
        const container = document.querySelector("#" + this.props.id + " div");
        const items = container.querySelectorAll(".gridContainer div");
        if (items && items.length > 0) {
            const lastItem = items[items.length - 1];
            return lastItem.offsetTop + lastItem.clientHeight - container.scrollTop <= container.clientHeight + 5;
        }
        return false;
    }

    scrollTable(toTop = false) {
        const container = document.querySelector("#" + this.props.id + " div");
        if (toTop || this.lastItemIsVisible()) {
            container.scroll({
                top: 0,
                behavior: 'smooth',
            });

        } else {
            container.scrollBy({
                top: this.state.itemHeight,
                behavior: 'smooth',
            });
        }
    }


    componentDidUpdate(prevProps) {
        // route data is updated
        if (this.props.data.route !== prevProps.data.route) {
            this.calculateTableHeight();
            this.scrollTable(true);
            this.setTimer();
        }
        if (this.state.needToScroll && this.state.textTimerDate && this.state.textTimerDate <= new Date()) {
            this.scrollTable();
            this.setTimer();
        }

    }


    render() {
        const route = this.props.route || {};
        const data = this.props.data.route || [];
        return (
            <div style={{ overflow: 'hidden', width: '100%', height: this.state.contentHeight + 'px' }}>
                <div className="gridContainer" style={{ gridTemplateColumns: '120px 4fr 1fr 1fr' }}>
                    {data.map((row, index) => {
                        return this.renderRow(row, route, index);
                    })}
                </div>
            </div>
        );
    }

    /*render1() {
        const route = this.props.route || {};
        const data = this.props.data.route || [];
        //console.log(route, data);
        return (
            <div style={{ overflow: 'hidden', width: '100%', height: this.state.tableHeight + 'px' }}>
                <table className="routeTable">
                    <tbody>
                        {data.map((row, index) => {
                            return this.renderRow(row, route, index);
                        })}
                    </tbody>
                </table>
            </div>
        );
    }*/

    renderRow(data, route, index) {
        let img = null;
        let bgColor = null;
        let className = null;
        switch (index) {
            case 0:
                img = `${config.api.static}route_selected.png`;
                bgColor = route.firstRowColor;
                className = 'routeTableImage';
                break;
            case this.props.data.route.length - 1:
                img = `${config.api.static}route_end.png`;
                bgColor = '';
                className = 'routeTableImageLast';
                break;
            default:
                img = `${config.api.static}route_point.png`;
                bgColor = '';
                className = 'routeTableImage';
                break;
        }
        return (
            <React.Fragment key={index}>
                <div className={className} style={{ backgroundColor: bgColor }}>
                    <img src={img} alt="route" width="120px" height="46px" />
                </div>
                <div className="station" style={{
                    paddingBottom: route.padding + 'px',
                    paddingTop: route.padding + 'px',
                    backgroundColor: bgColor
                }}>
                    {data.station}
                </div>
                <div className="time" style={{ backgroundColor: bgColor }}>
                    <Time time={data.time.planned} prefix={route.timePrefix} color={route.expectedColor} size={this.props.font.size} />
                </div>
                <div className="time" style={{ backgroundColor: bgColor }}>
                    <Time time={data.time.expected} prefix={route.timePrefix} size={this.props.font.size} />
                </div>
            </React.Fragment>
        );
    }


    /*renderRow1(data, route, index) {
        let img = null;
        let bgColor = null;
        let className = null;
        switch (index) {
            case 0:
                img = `${config.api.static}route_selected.png`;
                bgColor = route.firstRowColor;
                className = 'routeTableImage';
                break;
            case this.props.data.route.length - 1:
                img = `${config.api.static}route_end.png`;
                bgColor = '';
                className = 'routeTableImageLast';
                break;
            default:
                img = `${config.api.static}route_point.png`;
                bgColor = '';
                className = 'routeTableImage';
                break;
        }
        return (
            <tr key={index} style={{ backgroundColor: bgColor }}>
                <td className={className}>
                    <img src={img} alt="route" width="120px" height="46px" />
                </td>
                <td className="routeTableStation" style={{ paddingBottom: route.padding + 'px', paddingTop: route.padding + 'px' }}>
                    {data.station}
                </td>
                <td className="routeTableTime">
                    <Time time={data.time.planned} prefix={route.timePrefix} color={route.expectedColor} size={this.props.font.size} />
                </td>
                <td className="routeTableTime">
                    <Time time={data.time.expected} prefix={route.timePrefix} size={this.props.font.size} />
                </td>
            </tr>
        );
    }*/


}

const Time = (props) => {
    const { time, prefix, size } = props;
    const color = props.color || '';
    return (
        <div style={{ color: color }}>
            <span style={{ fontSize: size * 0.68 + 'px' }}>{prefix}</span>{formatTime(new Date(time * 1000))}
        </div>
    );
}

const formatTime = (time) => {
    const h = time.getHours();
    const hh = h < 10 ? '0' + h : h;
    const m = time.getMinutes();
    const mm = m < 10 ? '0' + m : m;
    return hh + ':' + mm;
}

export default RouteTable;