import { combineReducers } from 'redux';
import templateReducer from './templateReducer';
import screenReducer from './screenReducer';
import dataReducer from './dataReducer';
import timeReducer from './timeReducer';

const rootReducer = combineReducers({
    template: templateReducer,
    screens: screenReducer,
    data: dataReducer,
    time: timeReducer,
});

export default rootReducer;

