const config = {
    api: {
        template: 'https://caf.idevelopment.hu/data/templates/',
        screen: 'https://caf.idevelopment.hu/editor/action.php?action=5&id=',
        media: 'https://caf.idevelopment.hu/uploads/',
        vehicle: 'https://caf.idevelopment.hu/img/static/vehicle/',
        demoData: 'https://caf.idevelopment.hu/data/demo_data.json',
        static: 'https://caf.idevelopment.hu/img/static/',
    }
};

export default config;