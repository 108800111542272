import React from "react";
import DataParser from '../data/DataParser';

class DateTime extends React.Component {

    /*constructor(props) {
        super(props);
    }*/

    format(time, format) {
        if(!time || !format){
            return '';
        }

        const Y = time.getFullYear();
        const MO = time.getMonth()+1;
        const MOMO = MO < 10 ? '0' + MO : MO;
        const D = time.getDate();
        const DD = D < 10 ? '0' + D : D;
        const h = time.getHours();
        const hh = h < 10 ? '0' + h : h;
        const m = time.getMinutes();
        const mm = m < 10 ? '0' + m : m;
        const s = time.getSeconds();
        const ss = s < 10 ? '0' + s : s;
        
        return format
            .replace('yyyy', Y)
            .replace('MM', MOMO)
            .replace('M', MO)
            .replace('dd', DD)
            .replace('d', D)
            .replace('HH', hh)
            .replace('mm', mm)
            .replace('m', m)
            .replace('ss', ss)
            .replace('s', s);
        
        //return "12:45";

    }

    render() {
        //console.log(this.props);
        const data = this.props.data || {};
        const {format, dataField} = this.props.dateTime;
        let time;
        if(dataField) {
            const t = DataParser.parse(data, dataField) || null;
            if(t) {
                time = new Date(t*1000);
            }
        }else {
            time = this.props.time || new Date();
        }
        return (
            <div key={this.props.index}>
                {this.format(time, format)}
            </div>
        );
    }
}


export default DateTime;