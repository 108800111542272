const initState = {
};

const dataReducer = (state = initState, action) => {
    switch (action.type) {
        case 'DATA_LOADED':
            console.log('DATA_LOADED'/*, action.data*/);
            return {
                ...state,
                ...action.data,
            };
        default:
            return state;
    }
};

export default dataReducer;