import React from "react";
import DataParser from '../data/DataParser';


class Text extends React.Component {

    state = {
        textIndex: 0,
        textTimerDate: null,
    };

    /*constructor(props) {
        super(props);
    }*/

    getNextTextIndex() {
        const texts = this.props.text || []; 
        if(texts.length <= 0) {
            return 0;
        }
        let index = this.state.textIndex < texts.length-1 ? this.state.textIndex + 1 : 0;
        return index;
    }

    componentDidMount() {
        // is there any text to show?
        if(this.props.text.length > 0) {
            const now = new Date().getTime();
            const addTime = this.props.text[0].time;
            this.setState({
                textIndex: 0,
                textTimerDate: new Date(now + addTime),
            });
        }
    }

    componentDidUpdate(prevProps) {
        // text timer fired
        if(this.state.textTimerDate && this.state.textTimerDate <= new Date())
        {
            const nextIndex = this.getNextTextIndex();
            if(nextIndex !== this.state.textIndex) {
                const now = new Date().getTime();
                const addTime = this.props.text[nextIndex].time;
                this.setState({
                    textIndex: nextIndex,
                    textTimerDate: new Date(now + addTime),
                });
            }
        }
    }    

    render() {
        //console.log(this.props);
        const data = this.props.data || {};
        // no text to display
        if(this.props.text.length === 0) {
            return null;
        }
        const text = DataParser.parse(data, this.props.text[this.state.textIndex].text) || '';
        return (
            <span key={this.props.index} style={{ whiteSpace: 'pre-line' }}>{text}</span>
        );
    }
}


export default Text; 