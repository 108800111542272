class DataConverter {


    static convertScreenData(data, version) {
        const result = {};
        const aligns = {
            'left': 'flex-start',
            'center': 'center',
            'right': 'flex-end',
            'top': 'flex-start',
            'middle': 'center',
            'bottom': 'flex-end',
        };
        if (typeof version === "undefined") {
            const { size, position, background, font, dateTime, padding, align, text, /*alert,*/ ...rest } = data;
            result.position = {
                x: position.x,
                y: position.y,
                z: position.y,
                width: size.width,
                height: size.height,
            };
            if (background) {
                result.background = {
                    color: background.color,
                    padding: background.padding + 'px',
                    round: background.round,
                    align: background.align,
                    image: '',
                    flexAlign: aligns[background.align],
                    valign: aligns[background.valign],
                };
            }
            // Alert
            if (padding) {
                const image = background.backgroundMediaId ? `${background.backgroundMediaId}.${background.backgroundExt}` : '';
                result.background.image = image;
                result.background.padding = `${padding.padding.top}px ${padding.padding.right}px ${padding.padding.bottom}px ${padding.padding.left}px`;
                result.background.color = background.backgroundColor;
                result.background.round = padding.round;

            }
            // Alert
            if (align) {
                result.background.align = align.align;
                result.background.flexAlign = aligns[align.align];
                result.background.valign = aligns[align.valign];
            }
            // Alert
            /*if(alert) {
                result.alert = {
                    timing: alert.timing,
                    languageCount: alert.languageCount,
                    dataField: `[${alert.dataField}]`,
                }
            }*/
            if (font) {
                result.font = {
                    color: font.color,
                    type: font.type,
                    size: font.size,
                    bold: font.bold,
                    italic: font.italic,
                    underlined: font.underlined,
                };
            }
            if (dateTime) {
                result.dateTime = {
                    dataField: dateTime.dataField,
                }
                const dFormats = [
                    'HH:mm',
                    'HH:mm:ss',
                    'yyyy.MM.dd.',
                    'dd/MM/yyyy',
                    'dd.MM.yyyy',
                    'Hétfő',
                    'Monday',
                    'Montag'
                ]
                result.dateTime.format = dFormats[dateTime.format];
            }
            // Text
            if (text) {
                result.text = [];
                text.texts.forEach(item => {
                    if (item.text.length > 0) {
                        result.text.push({
                            text: item.text,
                            time: item.time * 1000,
                        });
                    }
                });
            }
            return { ...result, ...rest };
        }
        return result;
    }

}

export default DataConverter;