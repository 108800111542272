import React from 'react';
import config from '../config/config';

const Vehicle = (props) => {
    const data = props.data || {};
    const icon = data.train && data.train.icon;
    // show icon
    if (icon) {
        return (
            <img
                alt="vehicle"
                src={`${config.api.vehicle}${icon}`}
                style={{
                    width: props.vehicle.width,
                    height: props.vehicle.height,
                }}
            />
        );
    }
    const name = data.train && data.train.name;
    // show text
    if (name) {
        return (
            <p>{name}</p>
        );
    }
    return (
        <p></p>
    );
}

export default Vehicle;