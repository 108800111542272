import React, { Component } from 'react';


class Alert extends Component {
    
    render() {
        const data = this.props.data || {};
        if (!data.alert) {
            return (<div />);
        }
        if (this.props.alert.dataField === 'weather.alert') {
            return (
                <WeatherAlert alert={data.weather.alert} count={this.props.alert.languageCount} />
            );
        } else {
            return (
                <DefaultAlert alert={data.alert} count={this.props.alert.languageCount} />
            );
        }

    }

}

const WeatherAlert = (props) => {
    const { alert, count } = props;
    const keys = Object.keys(alert.title).slice(0, count);
    return (
        <div>
            {keys.map((key, index) => {
                return (
                    <div key={index}>
                        <p className="alertTitle">{alert.title[key]}</p>
                        <p className="alertDescription">{alert.description[key]}</p>
                    </div>
                );
            })}
        </div>
    );
};

const DefaultAlert = (props) => {
    const { alert, count } = props;
    const keys = Object.keys(alert).slice(0, count);
    return (
        <div>
            {keys.map((key, index) => {
                return (
                    <div key={index}>
                        <p className="alertDescription">{alert[key]}</p>
                    </div>
                );
            })}
        </div>
    );
};



export default Alert;