export default function (state = new Date(), action) {
    switch(action.type) {
        case 'UPDATE_TIME': {
            return action.time;
        }
        default: {
            return state;
        }
    }
}

