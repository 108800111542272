import rootReducer from '../redux/reducers/rootReducer';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(rootReducer,
    composeEnhancers(
        applyMiddleware(thunk),
    ));

export default store;

export const mapStateToProps = (state) => {
    return {
        template: state.template,
        screens: state.screens,
        data: state.data,
        time: state.time,
    }
}