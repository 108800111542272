const initState = {
};

const screenReducer = (state = initState, action) => {
    switch (action.type) {
        case 'SCREEN_LOADED':
            console.log('SCREEN_LOADED'/*, action.data, action.id*/);
            return {
                ...state, 
                [action.id]: action.data,
            };
            //return state;
        default:
            return state;
    }
};

export default screenReducer;