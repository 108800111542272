class DataParser {
    static parse(data, dataFieldCollection, index = null) {
        if (!dataFieldCollection) {
            return '';
        }
        // collect data fields
        let t = dataFieldCollection;
        const dataFields = [];
        t.replace(/\[[^\]]*]/g, function (a, b, c, d) {
            dataFields.push(a);
        });
        // get data for every fields
        dataFields.forEach((field) => {
            const txt = DataParser.deepValue(data, field.replace('[', '').replace(']', ''));
            if(txt) {
                t = t.replace(field, txt);
            }
            else {
                t = t.replace(field, '');
            }
        });
        return t;
    }

    static deepValue(obj, path) {
        var i;
        var len;
        for (i = 0, path = path.split('.'), len = path.length; i < len; i++) {
            if(obj){
                obj = obj[path[i]];
            }
        };
        return obj;
    };
}

export default DataParser;