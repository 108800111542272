import React, { Component } from 'react';

class RouteList extends Component {
    state = {
        height: 0,
        itemHeight: 0,
        needToScroll: false,
        textTimerDate: null,
    }

    calculateContentHeight() {
        const container = document.getElementById(this.props.id);
        const items = document.querySelectorAll(".routeListItem");
        if (items && items.length > 0) {
            const containerHeight = container.clientHeight;
            const itemHeight = items[0].clientHeight;
            const lastItem = items[items.length - 1];
            const scroll = lastItem.offsetTop + itemHeight > containerHeight;
            if (itemHeight > 0) {
                const c = containerHeight % itemHeight;
                this.setState({
                    height: containerHeight - c,
                    itemHeight: itemHeight,
                    needToScroll: scroll,
                });
            }
        }
    }

    lastItemIsVisible() {
        const container = document.querySelector("#" + this.props.id + " div");
        const items = document.querySelectorAll(".routeListItem");
        if (items && items.length > 0) {
            const lastItem = items[items.length - 1];
            return lastItem.offsetTop + lastItem.clientHeight - container.scrollTop <= this.state.height;
        }
        return true;
    }

    scrollList(toTop = false) {
        const container = document.querySelector("#" + this.props.id + " div");
        if (toTop || this.lastItemIsVisible()) {
            container.scroll({
                top: 0,
                behavior: 'smooth',
            });

        } else {
            container.scrollBy({
                top: this.state.itemHeight,
                behavior: 'smooth',
            });
        }
    }

    componentDidMount() {
        this.calculateContentHeight();
        this.scrollList(true);
        this.setTimer();
    }

    setTimer() {
        const now = new Date().getTime();
        const addTime = this.props.route.scrollTime * 1000;
        this.setState({
            textTimerDate: new Date(now + addTime),
        });
    }

    componentDidUpdate(prevProps) {
        // route data is updated
        if (this.props.data.route !== prevProps.data.route) {
            this.calculateContentHeight();
            this.scrollList(true);
            this.setTimer();
        }
        if (this.state.needToScroll && this.state.textTimerDate && this.state.textTimerDate <= new Date()) {
            this.scrollList();
            this.setTimer();
        }

    }

    render() {
        const route = this.props.route || {};
        const data = this.props.data.route || [];
        return (
            <div style={{ height: this.state.height + 'px', overflow: 'hidden' }}>
                {data.map((item, index) => {
                    return (
                        <span key={index} className="routeListItem">
                            <span>{item.station}</span>
                            <Time time={item.time} show={route.showTime} color={route.expectedColor} />
                            <span> {index < data.length-1 ? route.separator : ''}</span>
                        </span>
                    );
                })}

            </div>
        );
    }
}

const Time = (props) => {
    if (!props.show) {
        return null;
    }
    if (props.time.expected) {
        return (
            <span style={{ color: props.color }}> ({formatTime(new Date(props.time.expected * 1000))})</span>
        );
    }
    else {
        return (
            <span> ({formatTime(new Date(props.time.planned * 1000))})</span>
        );
    }
}

const formatTime = (time) => {
    const h = time.getHours();
    const hh = h < 10 ? '0' + h : h;
    const m = time.getMinutes();
    const mm = m < 10 ? '0' + m : m;
    return hh + ':' + mm;
}

export default RouteList;